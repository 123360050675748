
import {
    reactive
} from "vue";
import validate from 'ele-admin-pro/packages/validate';
export default {
    props: {
        formState: {
            type: Object,
            default: () => {},
        },
        visible: Boolean
    },

    data() {
        return {
            loading: false,
            // 表单规则
            rules: {
                user_account_code: [{
                    required: true,
                    message: this.$t("必填"),
                    trigger: "blur",
                }],
                bank_name: [{
                    required: true,
                    message: this.$t("必填"),
                    trigger: "blur",
                }],
                account_number: [{
                    required: true,
                    message: this.$t("必填"),
                    trigger: "blur",
                }],
                account_name: [{
                    required: true,
                    message: this.$t("必填"),
                    trigger: "blur",
                }],
                value: [{
                    required: true,
                    message: this.$t("必填"),
                    trigger: "blur",
                }, {
                    validator: (rule, value) => {
                        if (!value) {
                            return Promise.reject(this.$t("必填"), );
                        } else if (!validate.isNumber(value)) {
                            return Promise.reject(this.$t("user_withdraw.tips.tips_isNumber"));
                        } else {
                            return Promise.resolve();
                        }
                    },
                }],
            }
        };
    },
    computed: {
        // 表单信息重新计算表
        computedFormState: {
            get() {
                const obj = reactive(Object.assign({}, this.formState));
                obj.value =
                    this.formState.source_value != undefined ?
                    this.formState.source_value.toString() :
                    "";
                return obj;
            },
            set(val) {
                this.$emit("update:formState", val);
            },
        },
    },
    methods: {
        // 提交表单
        handleSubmit() {
            this.$refs.formRef
                .validate()
                .then(() => {
                    this.loading = true;
                    this.$http
                        .post(`/admin/withdraw/Create`, {
                            ...this.computedFormState,
                        })
                        .then((res) => {
                            if (res.status == 200 && res.data.code == 0) {
                                this.$message.success("操作成功");
                                this.$emit("update:visible", false);
                                this.$emit("reload");
                            } else {
                                this.$message.error(res.data.msg);
                            }
                        }).catch(() => {
                            this.$message.error(this.$t("操作失败"));
                        }).finally(() => {
                            this.loading = false;
                        })
                })
        }
    }
};
